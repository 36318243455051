<template>
  <SupplierDetailPage
    product_router="EndUserCommunicationProduct"
    page_title="Mobile Prepaid"
  ></SupplierDetailPage>
</template>

<script>
import SupplierDetailPage from '@/components/endUser/suppliersPages/SupplierDetailPage.vue';

export default {
  name: 'GameSupplier',
  components: {
    SupplierDetailPage
  },
}
</script>